const Text = () => {
  return (
    <div className="mainText">
      <div className="FROG">
        Unleash your inner <span className="frog">FROG</span>
      </div>
      <div className="smallText">
        Frogs are cool little creatures that chill all day.<br></br> We should
        all strive to lead such a life.
      </div>
    </div>
  );
};

export default Text;
