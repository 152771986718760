import logo from "../frog.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareXTwitter,
  faReddit,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  return (
    <div className="navbar">
      <a href="https://t.me/Reddit_Frog">
        <FontAwesomeIcon icon={faTelegram} />
      </a>
      <a href="https://x.com/FrogReddit">
        <FontAwesomeIcon icon={faSquareXTwitter} />
      </a>
      <a href="https://www.reddit.com/r/frogs/">
        <FontAwesomeIcon icon={faReddit} />
      </a>
      <div className="joinUs">Join us!</div>
      <div>{/* <img src={logo} className="logo" alt="frogLogo" /> */}</div>
    </div>
  );
};

export default Navbar;
