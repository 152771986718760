// import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Text from "./components/Text";
import logo from "./frog.png";

function App() {
  return (
    <div className="App">
      <Navbar />
      <img src={logo} className="logo2" alt="frogLogo" />
      <div className="whiteBlock">
        <Text />
        <div className="buttons">
          {/* <a className="socialsButton">View Social Media</a> */}
          <a
            className="buyButton"
            href="https://pump.fun/7MSN7jLZ5jc6xFctq4ouCWGyegEJZZyvmq9UGG92psQq"
          >
            Buy Some Frogs
          </a>
          {/* <a className="socialsButton">Telegram Channel</a> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
